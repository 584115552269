.klapper {

  &__content {
    justify-content: center;
  }

  &__content-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
