.collapsible-menu {

  &__list {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;

    &--show {
      max-height: 1000px;
      transition: max-height 0.25s ease-in;
    }
  }

  &__child-container {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__child-menu {
    color: $color-grey-light-1;
    padding: 0.8rem 1.6rem;
    font-size: 1.5rem;
    line-height: 1;

    &:hover {
      color: $color-white;
    }
  }
}
