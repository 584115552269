.admission-route {
  overflow: hidden;

  &__banner {
    position: absolute;
    z-index: 2;

    img {
      display: block;
      width: 100%;
      height: $banner-height;
      object-fit: cover;
    }
  }

  &__wrapper {
    padding-top: $banner-height;
    min-height: calc(100vh - #{$banner-height});
    display: flex;
    flex-direction: row;
  }

  &__main-panel {
    background-color: $color-white;
    width: 100%;
    min-height: calc(100vh - #{$banner-height});
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $bp-medium) {
      margin-left: 0;
      width: 100%;
    }
  }

  &__content-wrapper {
    z-index: 3;
    background: rgba($color-white, .3);
    padding: 2.75rem 2.25rem;
    width: 100%;
    flex-grow: 1;
  }
}
