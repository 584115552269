.card {
  background-color: $color-black-1;
  display: flex;
  width: 100%;
  min-width: 100%;

  &__body {
    padding: $gutter-small;
    flex: 1;
  }
}
