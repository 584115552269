.daily-and-monthly-report {

  &__form {
    margin-bottom: $gutter-default;
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: $gutter-default;

    td, th {
      border: 1px solid $color-black;
      padding: 8px;

      &.nominal {
        text-align: right;
      }
    }

    tr td, tr th {
      &:nth-child(1) {
        width: 1%;
      }
    }

    tr:nth-child(even){
      //background-color: #f2f2f2;
    }

    tr:nth-child(odd){
      //background-color: $color-white
    }

    tr:hover {
      //background-color: #ddd;
    }

    td {
      font-size: $font-size-default;
    }

    th {
      font-size: $font-size-default;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
    }

    &__actions {
      display: flex;

      button:nth-child(1) {
        margin-right: $gutter-extra-small;
      }
    }

    tr {
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        th {
          text-align: center;

          &.nominal {
            text-align: right;
          }
        }
      }
    }
  }
}
