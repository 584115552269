.input {
  margin-bottom: $gutter-extra-small;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 15px;

  & input[type=checkbox] {
    width:20px;
  }

  &__container {
    width: 100%;
    display: flex;

    &--error {

    }
    &.total {
      justify-content: flex-end;
    }
  }

  &__label {
    font-size: $font-size-small;

    &--error {
      color: $color-red;
    }
  }

  &__icon-left {
    order: 1;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-default;
    transition: border 0.30s ease-in-out;

    &__default {
      border-left: $color-fade-border;
      border-top: $color-fade-border;
      border-bottom: $color-fade-border;
      background-color: $color-white;
      border-radius: 4px 0 0 4px;
    }

    &__line {
      color: $color-white;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba($color-white, .5);
    }
  }

  &__text, &__file, &__datepicker {
    order: 2;
    padding: 0.9rem 1.2rem;
    height: 4rem;
    font-size: $font-size-default;
    background-clip: padding-box;
    outline: none;
    width: 100%;

    &::placeholder {
      color: rgba($color-white, .5);
    }

    &__default {
      border-radius: 4px;
      border: $color-fade-border;

      &--icon-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &--icon-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &__line {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba($color-white, .5);

      &--icon-left {
      }

      &--icon-right {
      }

    }

    &--no-border {
      border: 0;
    }

    &--disabled {
      color: $color-disabled;
      pointer-events: none;
    }

    &:active,
    &:focus {
      outline: none;
    }

    .react-date-picker__wrapper {
      border: 0;
      line-height: 20px;
    }
  }

  &__file{
    min-width: 270px;
    border: none;
  }

  &__icon-right {
    order: 3;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-default;
    transition: border 0.30s ease-in-out;

    &__default {
      border: none;
      border-radius: 4px 0 0 4px;
    }

    &__line {
      color: $color-white;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba($color-white, .5);
    }
  }

  &__err {
    font-size: $font-size-default;
    color: $color-red;
  }

  &__disabled {

  }

  &__no-margin {
    margin-bottom: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $color-white inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: $color-black !important;
  }

  &__text, textarea {
    transition: border 0.30s ease-in-out;
  }

  &__text {
    &__default:focus {
      border: 1px solid $color-black;
    }

    &__line:focus {
      border-bottom: 1px solid $color-white;
    }

    &__line:focus ~ .input__icon-left__line {
      border-bottom: 1px solid $color-white;
    }

    &__line:focus ~ .input__icon-right__line {
      border-bottom: 1px solid $color-white;
    }
  }

  &__preview {
    font-size: $font-size-default;
    &.editable {
      line-height: 40px;
    }
  }

  .file_input {
    flex-grow: 1;
  }

  .input_file__remove {
    line-height: 40px;
  }
}

.inputGroup {
  background-color: white;
  // color: white;
  display: block;
  margin: 10px 0;
  position: relative;

  label {
    padding: 10px;
    font-size: 15px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3C454C;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: '';
      background-color: #fbaf41;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

  }

  input:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #54E0C7;
      border-color: #54E0C7;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}
