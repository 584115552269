.laporan-rpp {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
      width: 50%;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 0rem;
        }
      }
    }

    &__header-title {
        margin-top: $gutter-small;
        display: flex;
        justify-content: center;
        align-items: center;
        
  
        &--column {
          flex-direction: column;
          text-align: center;
          
        }
        &--bold {
          font-weight: bold;
          font-size: 20px;
        }
  
        &--text {
          font-weight: bold;
          font-size: 18px;
        }
        &--text2 {
          font-size: 15px;
        }
      }

      &__body {
        margin-top: $gutter-extra-small;
      }
    
      &__table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: $gutter-default;
        
    
        td, th {
          border: 1px solid $color-black;
          padding: 8px;
         
          color: $color-black;
        }
    
        tr td, tr th {
          &:nth-child(1) {
            width: 1%;
          }
        }
    
        td.nominal {
          text-align: right;
        }
    
        td {
          font-size: $font-size-default;
          
        }
    
        th {
          font-size: $font-size-default;
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
        }
      }
    
    
      tfoot {
        td {
          font-weight:bold;
    
          &:last-child {
            border-left: 5px double #000000;
          }
        }
    
        tr:first-child td {
          border-top:5px double #000000;
        }
      }
    
      thead, tbody, tfoot {
        th:last-child, td:last-child {
          border-left: 5px double #000000;
        }
      }
    
      &__footer {
        padding: $gutter-extra-small 0 0 0;
        border: 1px solid $color-black;
      }
    
      &__footer-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
        &--left {
          padding: 0 $gutter-extra-small;
          flex: 3;
          display: flex;
          flex-direction: column;
          font-size: $font-size-default;
          font-weight: bold;
        }
    
        &--right {
          flex: 2;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
    
          p {
            font-size: $font-size-default;
            font-weight: bold;
          }
        }
    
        &--total {
          padding: 0 $gutter-extra-small;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $color-black;
        }
      }
    
      &__footer-bottom {
        margin-top: $gutter-extra-small;
        display: flex;
        flex-direction: row;
        border-top: 1px solid $color-black;
    
        div {
          min-height: 10rem;
          padding: $gutter-extra-small;
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          font-size: $font-size-small;
    
          p {
            &:nth-child(2) {
              font-weight: bold;
            }
          }
    
          &:not(:last-child) {
            border-right: 1px solid $color-black;
          }
        }
      }

    &__custom-form-column {
      display: flex;
      flex-direction: column;
  
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
  
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }
  }
  