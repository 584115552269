/*
COLORS

Primary: #eb2f64
Primary light: #FF3366
Primary dark: #BA265D

Grey light 1: #faf9f9
Grey light 2: #f4f2f2
Grey light 3: #f0eeee
Grey light 4: #ccc

Grey dark 1: #333
Grey dark 2: #777
Grey dark 3: #999

*/

$color-white: #fff;
$color-fade-white: rgba(255, 255, 255, .7);
$color-black: #000;
$color-red: red;
$color-blue: #007bff;
$color-green: green;
$color-disabled: rgba(0, 0, 0, .3);
$color-placeholder: rgba($color-black, .5);
$color-fade-border: 1px solid rgba($color-black, 0.3);
$color-border: 1px solid rgba($color-black, 1);
$color-background: #dddede;

$color-primary: #0088cc;
$color-primary-light: #33bddf;
$color-primary-dark: #022d41;

$color-black-1: #191c20;
$color-black-2: #2a3038;
$color-black-3: #2e2f32;
$color-black-4: #0d0d0d;
$color-black-5: #4b5564;

$color-grey-light-1: #a3a3a3;
$color-grey-light-2: #f4f2f2;
$color-grey-light-3: #f0eeee;
$color-grey-light-4: #ccc;

$color-grey-dark-1: #333;
$color-grey-dark-2: #777;
$color-grey-dark-3: #999;

$shadow-dark: 0 2rem 6rem rgba(0, 0, 0, .3);

$background-overlay: rgba(0, 0, 0, 0.5);

// FONT
$font-size-small: 1.4rem;
$font-size-default: 1.6rem;
$font-size-medium: 2.4rem;
$font-size-large: 3.6rem;


// GRID
$grid-width: 114rem;
$gutter-extra-small: 1rem;
$gutter-small: 2rem;
$gutter-default: 4rem;
$gutter-medium: 6rem;
$gutter-large: 8rem;

$bp-largest: 75em;     // 1200px
$bp-large: 68.75em;    // 1100px
$bp-medium: 56.25em;   // 900px
$bp-small: 37.5em;     // 600px
$bp-smallest: 31.25em; // 500px

$sidebar-width: 31rem;
$header-height: 7rem;
$banner-height: 17rem;
