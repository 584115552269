.select-coa {
  margin-bottom: $gutter-default;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--error {

    }
  }

  &__label {
    font-size: $font-size-small;

    &--error {
      color: $color-red;
    }
  }

  &__icon-left {
    order: 1;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-default;
    transition: border 0.30s ease-in-out;

    &__default {
      border: $color-fade-border;
      background-color: $color-white;
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }

    &__line {
      color: $color-white;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba($color-white, .5);
    }
  }

  &__text {
    order: 2;
    padding: 0.9rem 1.2rem;
    height: 4rem;
    font-size: $font-size-default;
    background-clip: padding-box;
    outline: none;
    width: 100%;


    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    //-webkit-appearance: button;
    //-webkit-user-select: none;

    &::placeholder {
      color: rgba($color-black, .5);
    }

    &__default {
      border-radius: 4px;
      border: $color-fade-border;

      &--icon-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &--icon-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &__line {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba($color-white, .5);

      &--icon-left {
      }

      &--icon-right {
      }
    }

    &--no-border {
      border: 0;
    }

    &--disabled {
      color: $color-disabled;
      background: transparent !important;
      cursor: not-allowed !important;
    }

    &:focus + .select-coa__list {
      display: block;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  &__icon-right {
    order: 3;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-default;
    transition: border 0.30s ease-in-out;

    &__default {
      border: $color-fade-border;
      border-radius: 0 4px 4px 0;
      border-left: 0;
    }

    &__line {
      color: $color-white;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba($color-white, .5);
    }
  }

  &__err {
    font-size: $font-size-default;
    color: $color-red;
  }

  &__disabled {

  }

  &__no-margin {
    margin-bottom: 0;
  }

  &__clear {
    position: absolute;
    top: 0;
    bottom: 0;
    right: $gutter-default;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-black;
    cursor: pointer;
    font-size: $font-size-default;
  }

  &__list {
    display: none;
    max-height: 30rem;
    width: 50rem;
    overflow: auto;
    position: absolute;
    top: 4.4rem;
    left: 0;
    right: 0;
    background-color: $color-white;
    color: $color-black;
    z-index: 1;
    border: 1px solid $color-black;
    border-radius: 4px;

    @media only screen and (max-width: $bp-medium) {
      max-height: 20rem;
    }
  }

  &__item {
    display: block;
    width: 100%;
    text-align: left;
    font-size: $font-size-default;
    color: $color-black;
    padding: 10px;

    &:hover {
      //background-color: $color-black-3;
    }
  }

  &__disabled {
    pointer-events: none;
  }

  &__text-disabled {
    color: $color-disabled;
  }

  &__icon-disabled {
    color: $color-disabled;
  }

  &__placeholder::placeholder {
    color: $color-black;
  }

  &__placeholder-disabled::placeholder {
    color: $color-disabled;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $color-white inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: $color-black !important;
  }

  &__text, textarea {
    transition: border 0.30s ease-in-out;
  }

  &__text {
    &__default:focus {
      border: 1px solid $color-black;
    }

    &__default:focus ~ .select-coa__icon-left__default {
      border-left-color: $color-black;
      border-top-color: $color-black;
      border-bottom-color: $color-black;
    }

    &__default:focus ~ .select-coa__icon-right__default {
      border-right-color: $color-black;
      border-top-color: $color-black;
      border-bottom-color: $color-black;
    }

    &__line:focus {
      border-bottom: 1px solid $color-white;
    }

    &__line:focus ~ .input__icon-left__line {
      border-bottom: 1px solid $color-white;
    }

    &__line:focus ~ .input__icon-right__line {
      border-bottom: 1px solid $color-white;
    }
  }

  &__title {
    &,
    &:link,
    &:visited {
      width: 100%;
      text-align: left;
      color: $color-black;
      cursor: pointer;
      transition: all .2s;
    }

    &:hover {
      background-color: $color-background;
    }

    &:active {
      background-color: $color-primary-dark;
    }

    &:active,
    &:focus {
      outline: none;
    }

    p {
      padding: $gutter-extra-small;
      border-bottom: 1px solid rgba($color-black, .5);
    }
  }

  &__category {
    &,
    &:link,
    &:visited {
      width: 100%;
      text-align: left;
      color: $color-black;
      cursor: pointer;
      transition: all .2s;
    }

    &:hover {
      background-color: $color-background;
    }

    &:active {
      background-color: $color-primary-dark;
    }

    &:active,
    &:focus {
      outline: none;
    }
    p {
      padding: $gutter-extra-small;
      border-bottom: 1px solid rgba($color-black, .5);
      padding-left: $gutter-default;
    }
  }

  &__group {
    &,
    &:link,
    &:visited {
      width: 100%;
      text-align: left;
      color: $color-black;
      cursor: pointer;
      transition: all .2s;
    }

    &:hover {
      background-color: $color-background;
    }

    &:active {
      background-color: $color-primary-dark;
    }

    &:active,
    &:focus {
      outline: none;
    }
    p {
      border-bottom: 1px solid rgba($color-black, .5);
      padding: $gutter-extra-small;
      padding-left: $gutter-medium;
    }
  }

  &__account {
    &,
    &:link,
    &:visited {
      width: 100%;
      text-align: left;
      color: $color-black;
      cursor: pointer;
      transition: all .2s;
    }

    &:hover {
      background-color: $color-background;
    }

    &:active {
      background-color: $color-primary-dark;
    }

    &:active,
    &:focus {
      outline: none;
    }

    p {
      border-bottom: 1px solid rgba($color-black, .5);
      padding: $gutter-extra-small;
      padding-left: $gutter-large;
    }
  }
}
