.pengisian-kd{
  &__table{
      
  }
    
  &__form-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: $gutter-default;

    td, th {
      border: 1px solid $color-black;
      padding: 8px;
    }

    td {
      font-size: $font-size-default;
    }

    th {
      font-size: $font-size-default;
      padding-top: 12px;
      padding-bottom: 12px;
    //   text-align: center;
    }
  }

  &__add-more {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__table-footer {
    td {
      &:first-child {
        text-align: right;
      }
    }
  }
}