.swal2-container {
  width: auto;
  .swal2-popup {
    width: auto;
  }
}

[class^=swal2] {
  width: auto;
}
.modal {
  visibility: hidden;
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &__overlay {
    z-index: 998;
    visibility: hidden;
    position: fixed;
    background-color: $color-black;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .15s linear;

    &--show {
      visibility: visible;
      background-color: $color-black;
      opacity: .5;
    }
  }

  &__button-container {
    width: 100%;
  }

  &__button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: $color-primary;
    font-size: $font-size-default;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.2rem 1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all .2s;

    &:hover {
      background-color: $color-primary-light;
    }

    &:active {
      background-color: $color-primary-dark;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  &__content {
    position: relative;
    background-color: $color-black-3;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    opacity: 0;
    transition: all .15s linear;

    &--show {
      visibility: visible;
      background-color: $color-black;
      opacity: 1;
    }
  }

  &__header {
    padding: $gutter-small;
    border-bottom: 1px solid $color-fade-white;
  }

  &__body {
    padding: $gutter-small;
    border-bottom: 1px solid $color-fade-white;
  }

  &__footer {
    padding: $gutter-small;
  }

  &__close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;

    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
