.manage-staff {

  &__form-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: $gutter-default;

    td, th {
      border: 1px solid $color-black;
      padding: 8px;
    }

    & > tr > td {
      &:nth-child(1) {
        width: 1%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 30%;
      }
    }

    td {
      font-size: $font-size-default;
    }

    th {
      font-size: $font-size-default;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
    }

    &__actions {
      display: flex;

      button:nth-child(1) {
        margin-right: $gutter-extra-small;
      }
    }
  }

  &__add-more {
    div {
      width: 10%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__table-footer {
    td {
      &:first-child {
        text-align: right;
      }
    }
  }
}

.manage-staff-form {

  &__custom-form {

  }

  &__custom-form-row {
    display: flex;
    flex-direction: row;

    &__field {
      display: flex;
      flex-direction: row;
      flex: 1;

      &:not(:nth-child(1)) {
        margin-left: 1rem;
      }
    }
  }

  &__custom-form-row2 {
    display: flex;
    flex-direction: row;

    &__field {
      display: flex;
      flex-direction: row;
      flex: 1;

      &:not(:nth-child(1)) {
        margin-left: 45rem;
      }
    }
  }

  &__custom-form-column {
    display: flex;
    flex-direction: column;

    &__field {
      display: flex;
      flex-direction: column;
      flex: 1;

      &:not(:nth-child(1)) {
        margin-left: $gutter-small;
      }
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      &:nth-child(2) {
        margin-left: $gutter-small;
      }
    }
  }
}

