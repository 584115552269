.manage-admission {

  &__salary-form {
    display: flex;
    flex-direction: row;

    > div:nth-child(2) {
      margin-left: $gutter-small;
    }
  }

  &__footer {

  }

  &__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      &:nth-child(2) {
        margin-left: $gutter-small;
      }
    }
  }
}
