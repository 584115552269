.dashboard {
  overflow: hidden;

  &__banner {
    position: absolute;
    z-index: 2;

    img {
      display: block;
      width: 100%;
      height: $banner-height;
      object-fit: cover;
    }
  }

  &__wrapper {
    padding-top: $banner-height;
    min-height: calc(100vh - #{$banner-height});
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__main-panel {
    background-color: $color-white;
    //margin-right: $gutter-small;
    width: calc(100vh - #{$sidebar-width});
    height: calc(100vh - #{$banner-height});
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media only screen and (max-width: $bp-medium) {
      margin-left: 0;
      width: 100%;
    }
  }

  &__content-wrapper {
    z-index: 3;
    background: rgba($color-white, .3);
    padding: 2.75rem 2.25rem;
    width: 100%;
    flex: 1 1 auto;
    overflow: auto;
    position: relative;
  }

  .unit_selector {
    position: absolute;
    margin-top:5px;
    right: 40px;
    div.search-select__list {
      right: 0;
      left: auto;
    }

    input.search-select__text {
      text-overflow: ellipsis;
      padding-right: 2rem;
    }

    .search-select__clear {
      right: 1rem;
    }
  }
}
