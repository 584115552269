.param-deskripsi {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 0rem;
        }
      }
    }

    &__header-title {
      margin-top: $gutter-small;
      display: flex;
      justify-content: center;
      align-items: center;
      

      &--column {
        flex-direction: column;
        text-align: center;
        
      }
      &--bold {
        font-weight: bold;
        font-size: 20px;
        text-decoration: underline;
      }

      &--text {
        font-weight: bold;
        font-size: 18px;
      }
      &--text2 {
        font-size: 15px;
      }
    }

    &__custom-form-column {
      display: flex;
      flex-direction: column;
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }

    &__custom-form-column2 {
      display: flex;
      flex-direction: column;
      width: 49%;
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
      }
    }
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
  
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }
  }
  