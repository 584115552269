.salary-report{

    &__title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: right;
    }
   &__img {
      display: block;
      width: 100%;
      height: $banner-height;
      object-fit: cover;
    }
  
    &__table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: $gutter-default;
  
      td, th {
        border: 0px solid $color-black;
        padding: 8px;
      }
  
      tr td, tr th {
        &:nth-child(1) {
          width: 1%;
        }
      }
    
      td {
        font-size: $font-size-default;
      }
  
      th {
        font-size: $font-size-default;
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
      }
  
      &__actions {
        display: flex;
  
        button:nth-child(1) {
          margin-right: $gutter-extra-small;
        }
      }
  
      tr {
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          th {
            text-align: center;
          }
        }
      }
    }
  }
  