.cetak-buku-induk {
  &__content {
    margin-top: $gutter-default;
    margin-left: 5rem;
  }
  &__field {
    margin-top: -4rem;
    margin-left: 70rem;
    font-weight: bold;
  }

  &__header {
    padding: $gutter-small;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__header-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--left {
      font-size: $font-size-default;
      font-weight: bold;
    }

    &--right {
      font-size: $font-size-default;
      font-weight: bold;
    }
  }

  &__header-title {
    margin-top: $gutter-small;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20rem;

    &--column {
      flex-direction: column;
      text-align: center;
    }
    &--bold {
      font-weight: bold;
    }
  }

  &__box {
    display: flex;
    // flex-direction: row;
    justify-content: space-between;

    &--square {
      font-size: $font-size-default;
      width: 200px;
      border: 1px solid black;
      padding: 20px;
      margin: 5px;
      margin-left: 50rem;
    }

    &--square2 {
      font-size: $font-size-default;
      flex-direction: column;
      width: 200px;
      border: 1px solid black;
      padding: 80px;
      margin: 5px;
      margin-left: 50rem;
    }
  }

  &__title {
    width: 300%;
    flex-direction: row;
    justify-content: space-between;
  }
  &__title2 {
    width: 300%;
    margin-left: 1rem;
    flex-direction: row;
  }
  &__title3 {
    width: 300%;
    flex-direction: column;
  }
  &__title4 {
    flex-direction: column;
    margin-right: -10rem;
  }
  &__title5 {
    display: flex;
    width: 300%;
    margin-left: 1rem;
    flex-direction: row;
  }

  &__beasiswa {
    width: 100%;
    margin-left: 24rem;
    flex-direction: column;
  }

  &__header-title2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    // align-items: flex-start;
    // margin-top: 10rem;
    &--left {
      font-size: $font-size-default;
      flex-direction: column;
      margin-left: 2rem;
    }

    &--right {
      font-size: $font-size-default;
      flex-direction: column;
      width: 200px;
      border: 1px solid black;
      padding: 80px;
      margin: 5px;
      margin-right: 5rem;
    }
  }

  &__header-title3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    // align-items: flex-start;
    // margin-top: 10rem;

    &--left {
      font-size: $font-size-default;
      flex-direction: column;
      width: 100%;
      margin-left: 2rem;
    }

    &--right {
      font-size: $font-size-default;
      flex-direction: column;
      width: 100%;
    }
  }

  &__header-title4 {
    margin-left: 2rem;
  }

  &__body {
    margin-top: $gutter-extra-small;
  }

  &__table-test {
    width: 250%;
    td {
      font-size: 16px;
      padding-top: 1px;
    }
  }

  &__table-test_nisn {
    width: 100%;
    td {
      font-size: 14px;
      padding-top: 1px;
      &.nominal {
        text-align: left;
      }
    }
  }

  &__table-test2 {
    width: 76%;
    td {
      font-size: 16px;
      padding-top: 1px;
    }
  }

  &__table-test3 {
    width: 76%;
    td {
      font-size: 16px;
      padding-top: 1px;
    }
  }
  &__table-test4 {
    width: 80%;
    td {
      font-size: 16px;
      padding-top: 1px;
    }
  }

  &__table-test5 {
    width: 80%;
    td {
      font-size: 16px;
      padding-top: 1px;
    }
  }
  &__table-test6 {
    width: 80%;
    td {
      font-size: 16px;
      padding-top: 1px;
    }
  }

  &__table-test7 {
    width: 92%;
    td {
      font-size: 16px;
      padding-top: 1px;
    }
  }
  &__table-test8 {
    width: 50%;
    td {
      font-size: 16px;
      padding-top: 1px;
    }
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: $gutter-default;

    td,
    th {
      border: 1px solid $color-black;
      padding: 8px;
      background-color: $color-white;
      color: $color-black;
      &.nominal {
        text-align: left;
      }
    }

    tr td,
    tr th {
      &:nth-child(1) {
        width: 1%;
      }
    }

    tr:nth-child(even) {
      //background-color: #f2f2f2;
    }

    tr:nth-child(odd) {
      //background-color: $color-white
    }

    tr:hover {
      //background-color: #ddd;
    }

    td {
      font-size: $font-size-default;
    }

    th {
      font-size: $font-size-default;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
    }

    &__actions {
      display: flex;

      button:nth-child(1) {
        margin-right: $gutter-extra-small;
      }
    }

    tr {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        th {
          text-align: center;
          &.nominal {
            text-align: right;
          }
        }
      }
    }
  }

  tfoot {
    td {
      font-weight: bold;

      &:last-child {
        border-left: 5px #000000;
      }
    }

    tr:first-child td {
      border-top: 5px #000000;
    }
  }

  thead,
  tbody,
  tfoot {
    //   th:last-child, td:last-child {
    //     border-left: 5px double #000000;
    //   }
  }

  &__footer {
    padding: $gutter-extra-small 0 0 0;
    border: 1px solid $color-black;
  }

  &__footer-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--left {
      padding: 0 $gutter-extra-small;
      flex: 3;
      display: flex;
      flex-direction: column;
      font-size: $font-size-default;
      font-weight: bold;
    }

    &--right {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      p {
        font-size: $font-size-default;
        font-weight: bold;
      }
    }

    &--total {
      padding: 0 $gutter-extra-small;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $color-black;
    }
  }

  &__footer-bottom {
    margin-top: $gutter-extra-small;
    display: flex;
    flex-direction: row;
    border-top: 1px solid $color-black;

    div {
      min-height: 10rem;
      padding: $gutter-extra-small;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: $font-size-small;

      p {
        &:nth-child(2) {
          font-weight: bold;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid $color-black;
      }
    }
  }
}

.cetak-buku-induk2 {
  &__content {
    // margin-top: $gutter-default;
    // margin-left: 5rem;
  }
  &__field {
    margin-top: -4rem;
    margin-left: 70rem;
    font-weight: bold;
  }

  &__header {
    // padding: $gutter-small;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__header-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--left {
      font-size: $font-size-default;
      font-weight: bold;
    }

    &--right {
      font-size: $font-size-default;
      font-weight: bold;
    }
  }

  &__header-title {
    margin-top: $gutter-small;
    margin-bottom: $gutter-small;
    display: flex;
    justify-content: center;
    align-items: center;

    &--column {
      flex-direction: column;
      text-align: center;
    }
    &--bold {
      font-weight: bold;
    }
  }

  &__box {
    display: flex;
    // flex-direction: row;
    justify-content: space-between;

    &--square {
      font-size: $font-size-default;
      width: 200px;
      border: 1px solid black;
      padding: 20px;
      margin: 5px;
    }

    &--square2 {
      font-size: $font-size-default;
      flex-direction: column;
      border: 1px solid black;
      padding: 80px;
      margin: 5px;
    }
  }

  &__title {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  &__title2 {
    width: 300%;
    margin-left: 1rem;
    flex-direction: row;
  }
  &__title3 {
    width: 300%;
    flex-direction: column;
  }
  &__title4 {
    flex-direction: column;
  }
  &__title5 {
    display: flex;
    width: 300%;
    margin-left: 1rem;
    flex-direction: row;
  }

  &__beasiswa {
    width: 100%;
    margin-left: 24rem;
    flex-direction: column;
  }

  &__header-title2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    // align-items: flex-start;
    // margin-top: 10rem;
    &--left {
      font-size: $font-size-default;
      flex-direction: column;
      margin-left: 2rem;
    }

    &--right {
      font-size: $font-size-default;
      flex-direction: column;
      width: 200px;
      border: 1px solid black;
      padding: 80px;
      margin: 5px;
      margin-right: 5rem;
    }
  }

  &__header-title3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    // align-items: flex-start;
    // margin-top: 10rem;

    &--left {
      font-size: $font-size-default;
      flex-direction: column;
      width: 100%;
      margin-left: 2rem;
    }

    &--right {
      font-size: $font-size-default;
      flex-direction: column;
      width: 100%;
    }
  }

  &__header-title4 {
    margin-left: 2rem;
  }

  &__body {
    margin-top: $gutter-extra-small;
  }

  &__table-test {
    width: 100%;
    td {
      font-size: 14px;
      padding-top: 1px;
      vertical-align: top;
    }
  }

  &__table-test_nisn {
    width: 100%;
    td {
      font-size: 14px;
      padding-top: 1px;
      &.nominal {
        text-align: left;
      }
    }
  }

  &__table-test7 {
    width: 92%;
    td {
      font-size: 16px;
      padding-top: 1px;
    }
  }

  &__table-test8 {
    width: 50%;
    td {
      font-size: 16px;
      padding-top: 1px;
    }
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: $gutter-default;

    td,
    th {
      border: 1px solid $color-black;
      padding: 8px;
      background-color: $color-white;
      color: $color-black;
      &.nominal {
        text-align: left;
      }
    }

    tr td,
    tr th {
      &:nth-child(1) {
        width: 1%;
      }
    }

    tr:nth-child(even) {
      //background-color: #f2f2f2;
    }

    tr:nth-child(odd) {
      //background-color: $color-white
    }

    tr:hover {
      //background-color: #ddd;
    }

    td {
      font-size: $font-size-default;
    }

    th {
      font-size: $font-size-default;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
    }

    &__actions {
      display: flex;

      button:nth-child(1) {
        margin-right: $gutter-extra-small;
      }
    }

    tr {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        th {
          text-align: center;
          &.nominal {
            text-align: right;
          }
        }
      }
    }
  }

  &__table-view-identitas {
    width: 100%;
    td {
      font-size: 12px;

      div.input {
        margin: 0;
      }

      input {
        height: 3.5rem;
        font-size: 12px;
        padding: 5px;
      }

      &.list {
        width: 20px;
      }

      &.label_12 {
        width: 200px;
      }

      &.contents {
        padding: 3px 0px 3px 0px;
      }

      &.opt {
        padding-right: 10px;
        font-size: 11px;
      }

      &.field {
        padding-right: 15px;
      }

      &.xsfield {
        padding-right: 10px;
        input {
          height: 2.5rem;
        }
      }

      &.titik2 {
        width: 20px;
        padding: 0 5px 0 5px;
        text-align: center;
      }
    }
  }

  tfoot {
    td {
      font-weight: bold;

      &:last-child {
        border-left: 5px #000000;
      }
    }

    tr:first-child td {
      border-top: 5px #000000;
    }
  }

  thead,
  tbody,
  tfoot {
    //   th:last-child, td:last-child {
    //     border-left: 5px double #000000;
    //   }
  }

  &__footer {
    padding: $gutter-extra-small 0 0 0;
    border: 1px solid $color-black;
  }

  &__footer-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--left {
      padding: 0 $gutter-extra-small;
      flex: 3;
      display: flex;
      flex-direction: column;
      font-size: $font-size-default;
      font-weight: bold;
    }

    &--right {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      p {
        font-size: $font-size-default;
        font-weight: bold;
      }
    }

    &--total {
      padding: 0 $gutter-extra-small;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $color-black;
    }
  }

  &__footer-bottom {
    margin-top: $gutter-extra-small;
    display: flex;
    flex-direction: row;
    border-top: 1px solid $color-black;

    div {
      min-height: 10rem;
      padding: $gutter-extra-small;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: $font-size-small;

      p {
        &:nth-child(2) {
          font-weight: bold;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid $color-black;
      }
    }
  }
}

.modal-pembayaran {
  &__table-test {
    width: 100%;
    td {
      font-size: 14px;
      vertical-align: top;
      padding: 5px;
      border-bottom: 1px solid #fbaf41;
    }

    tr:last-child {
      td {
        border-bottom: 2px solid #fbaf41;
      }
    }
  }
}

.create-invoice-rutin {

  &__table-test {
    width: 100%;
    
    .status {
      width: 100px;
    }

    &tr td, tr th {
      border: unset;
      
      &.input {
        margin: 0;
        &input[type=checkbox] {
          width:100%;
          height:100%;
          // text-align: top;
        }
      }

      &:nth-child(1) {
        width: 2%;
      }

    }

    &td {
      font-size: 14px;
      padding-top: 1px;
      vertical-align: top;
    }
  }

  &__table-detail {
    width: 100%;
    &tr td, tr th {
      border: unset;
      
      &.input {
        margin: 0;
      }
    }

    &td {
      font-size: 14px;
      padding-top: 1px;
      vertical-align: top;
    }
  }

  tfoot {
    td {
      font-weight: bold;

      &:last-child {
        border-left: 5px #000000;
      }
    }

    tr:first-child td {
      border-top: 5px #000000;
    }
  }
}

.modal-riwayat {
  &__table-test {
    border-collapse: collapse;
    width: 100%;
    td, th {
      font-size: 14px;
      vertical-align: top;
      padding: 5px;

      &:last-child {
        width: 10%;
      }
    }
    tr.invoice {
      border-bottom: 1px solid lightgrey;
    }
  }
}