.student-number {

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__header-detail {
    display: flex;
    flex-direction: row;

    p {
      &:nth-child(1) {
        flex: 1;
      }
      &:nth-child(2) {
        flex: 9;
      }
    }
  }

  &__content {
  }

  &__content-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
