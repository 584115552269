.cetak-observasi {

    &__content {
      margin-top: $gutter-default;
    }

    &__custom-form {
  
    }

    &__header {
        padding: $gutter-small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid $color-black;
        margin-left: 3rem;
      }
  
    &__custom-form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
          margin-left: 1rem;
         
        }
      }

      &__custom-form-column {
        display: flex;
        flex-direction: column;
    
        &__field {
          display: flex;
          flex-direction: column;
          flex: 1;
    
          &:not(:nth-child(1)) {
            margin-left: $gutter-small;
          }
        }
      }

    &__field {
        margin-top: -4rem;
        margin-left: 70rem;
        font-weight: bold;
    }
  
    
    &__header-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      &--left {
        font-size: $font-size-default;
        font-weight: bold;
      }
  
      &--right {
        font-size: $font-size-default;
        font-weight: bold;
      }
    }
  
    &__header-title {
      margin-top: $gutter-small;
      display: flex;
      justify-content: center;
      align-items: center;
      

      &--column {
        flex-direction: column;
        text-align: center;
        margin-right:20rem;
        
      }
      &--bold {
        font-weight: bold;
      }

      &--text {
        font-size: 18px;
        margin-left:15rem;
        width: 40%;
      }
    }

     
    

    &__box {
        display: flex;
        // flex-direction: row;
        justify-content: space-between;
        
        &--square{
          font-size: $font-size-default;
          width: 200px;
          border: 1px solid black;
          padding: 20px;
          margin: 5px;
          margin-left: 100rem;

        }
             
        &--square2{
            font-size: $font-size-default;
            flex-direction: column;
            width: 150px;
            height:100px;
            border: 1px solid black;
            margin: 5px;
            margin-left: 5rem;
           
          }
        
      }

    &__title{
        width: 300%;
        flex-direction: row;
        justify-content: space-between;
    }
    &__title2 {
      width: 300%;
      margin-left: 1rem;
      flex-direction: column;
    }
    &__title3 {
      width: 300%;
      flex-direction: column;
    }
    &__title4 {
      flex-direction: column;
    }
     
    &__beasiswa {
      width: 100%;
      margin-left: 24rem;
      flex-direction: column;
    }

    &__header-title2 {
        margin-top: $gutter-small;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:18px;
  
        &--column {
          flex-direction: column;
          text-align: center;
          margin-right: 50rem;
          
        }
        &--bold {
          font-weight: bold;
          font-size: 15px;
        }
      }

      &__header-between {
        display: flex;
        // flex-direction: row;
        justify-content: space-between;
        
        // align-items: flex-start;
        // margin-top: 10rem;
    
        &--left {
          
          font-size: $font-size-default;
          flex-direction: column;
          
        }
    
        &--right {
          font-size: $font-size-default;
          flex-direction: column;
          
        }
      }


  
    &__header-info {
  
    }
  
    &__body {
      margin-top: $gutter-extra-small;
      margin-left: 3rem;
    }
  
    &__table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: $gutter-default;
        
    
        td, th {
          border: 1px solid $color-black;
          padding: 8px;
          background-color: $color-white;
          color: $color-black;
        }
    
        tr td, tr th {
          &:nth-child(1) {
            width: 1%;
          }
        }
    
        td.nominal {
          text-align: right;
        }
    
        td {
          font-size: $font-size-default;
          
        }
    
        th {
          font-size: $font-size-default;
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
        }
      }
  
    tfoot {
      td {
        font-weight:bold;
  
        &:last-child {
          border-left: 5px double #000000;
        }
      }
  
      tr:first-child td {
        border-top:5px double #000000;
      }
    }
  
    thead, tbody, tfoot {
    //   th:last-child, td:last-child {
    //     border-left: 5px double #000000;
    //   }
    }
  
    &__footer {
        padding: $gutter-small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid $color-black;
        margin-left: 3rem;
        margin-top:-3rem;
      }
  
    &__footer-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  
      &--left {
        padding: 0 $gutter-extra-small;
        flex: 3;
        display: flex;
        flex-direction: column;
        font-size: $font-size-default;
        font-weight: bold;
      }
  
      &--right {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
  
        p {
          font-size: $font-size-default;
          font-weight: bold;
        }
      }
  
      &--total {
        padding: 0 $gutter-extra-small;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-black;
      }
    }
  
    &__footer-bottom {
      margin-top: $gutter-extra-small;
      display: flex;
      flex-direction: row;
      border-top: 1px solid $color-black;
  
      div {
        min-height: 10rem;
        padding: $gutter-extra-small;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        font-size: $font-size-small;
  
        p {
          &:nth-child(2) {
            font-weight: bold;
          }
        }
  
        &:not(:last-child) {
          border-right: 1px solid $color-black;
        }
      }
    }
  }
  