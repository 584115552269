.salary-option {
  margin-bottom: $gutter-extra-small;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__container {
    width: 100%;
    display: flex;

    &--error {

    }
  }

  &__label {
    font-size: $font-size-small;

    &--error {
      color: $color-red;
    }
  }

  &__icon-left {
    order: 1;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-default;
    transition: border 0.30s ease-in-out;

    &__default {
      border: none;
      background-color: $color-white;
      border-radius: 4px 0 0 4px;
    }

    &__line {
      color: $color-white;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba($color-white, .5);
    }
  }

  &__text {
    order: 2;
    padding: 0.9rem 1.2rem;
    height: 4rem;
    font-size: $font-size-default;
    background-clip: padding-box;
    outline: none;
    width: 100%;

    &::placeholder {
      color: rgba($color-white, .5);
    }

    &__default {
      border-radius: 4px;
      border: $color-fade-border;

      &--icon-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &--icon-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &__line {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba($color-white, .5);

      &--icon-left {
      }

      &--icon-right {
      }
    }

    &--no-border {
      border: 0;
    }

    &--disabled {
      color: $color-disabled;
      pointer-events: none;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  &__icon-right {
    order: 3;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-default;
    transition: border 0.30s ease-in-out;

    &__default {
      border: none;
      border-radius: 4px 0 0 4px;
    }

    &__line {
      color: $color-white;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba($color-white, .5);
    }
  }

  &__err {
    font-size: $font-size-default;
    color: $color-red;
  }

  &__disabled {

  }

  &__no-margin {
    margin-bottom: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $color-white inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: $color-black !important;
  }

  &__text, textarea {
    transition: border 0.30s ease-in-out;
  }

  &__text {
    &__default:focus {
      border: 1px solid $color-black;
    }

    &__line:focus {
      border-bottom: 1px solid $color-white;
    }

    &__line:focus ~ .salary-option__icon-left__line {
      border-bottom: 1px solid $color-white;
    }

    &__line:focus ~ .salary-option__icon-right__line {
      border-bottom: 1px solid $color-white;
    }
  }

  &__preview {
    font-size: $font-size-default;
  }
}
