.lembar-harian2 {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-left: 1rem;
       
      }
    }

    &__custom-form-column {
      display: flex;
      flex-direction: column;
  
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }

    &__custom-form-width {
        display: flex;
        flex-direction: column;
    
        &__field {
          display: flex;
          flex-direction: column;
          flex: 1;
          width: 10%;
    
          &:not(:nth-child(1)) {
            margin-left: $gutter-small;
          }
        }
      }


      &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        
       
    }
      
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
  
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }
  }
  