.pagination {
  display: inline-block;
  background-color: $color-white;
}

.pagination button {
  color: $color-black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid $color-black;
}

.pagination button.active {
  background-color: $color-primary;
  color: $color-white;
  border: 1px solid $color-primary;
}

.pagination button:hover:not(.active) {
  background-color: #ddd;
}

.pagination button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
