.notifications {
  &-wrapper {
    position: absolute;
    width: 400px;
    max-height: 500px;
    overflow:hidden;
    left: -240px;
    top: 45px;
    background: #FFFFFF;
  }

  &-content {
    position: relative;
    overflow-y: scroll;
    max-width: 400px;
    max-height: 500px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-basis: 100%;
  }

  &-item {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 100%;
    border-bottom: 1px solid #CCCCCC;
    padding: 10px;
  }

  &-subject {
    font-size:14px;
    width:100%;
    color: #999999;
  }
  &-message {

  }
}
