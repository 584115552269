.buat-pelanggaran {

    &__custom-form {

  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 1rem;
        }
      }
    }

    &__custom-form-column {
      display: flex;
      flex-direction: column;
  
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 0rem;
        }
      }
    }

    &__body {
        margin-top: $gutter-extra-small;
      }
    &__table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: $gutter-default;
        position: relative;
        
    
        td, th {
          border: 1px solid $color-black;
          padding: 8px;
         
          color: $color-black;
        }
    
        tr td, tr th {
          &:nth-child(1) {
            width: 5%;
          }
        }
    
        td.nominal {
          text-align: right;
        }
    
        td {
          font-size: $font-size-default;
          
        }
    
        th {
          font-size: $font-size-default;
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
        }
      }

    &__content {
      position: relative;
      width: 500px;
    }
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    //   margin-left: 60rem;
    //   margin-top:-6rem;
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }
  }
  