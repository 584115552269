.button-ekskul {

    &__table-wrapper {
      min-width: 100%;
    }
  
    &__form-table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: $gutter-default;
      position: relative;
  
      td, th {
        border: 1px solid $color-black;
        padding: 8px;
        &.col_rekomendasi, tr.total &.col_rekomendasi {
          background-color:#909090;
        }
      }

     
  
      tr.total td.col_rekomendasi {
        background-color:#909090;
      }
  
      td {
        font-size: $font-size-default;
      }
  
      th {
        font-size: $font-size-default;
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
      }
  
      thead {
        tr {
          th {
            position: sticky;
            top: -29px;
            z-index: 2;
          }
          &:nth-child(2) th {
            top: 21px;
          }
        }
      }
    }

    &_checkbox_checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
  
    &__form-table2 {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: $gutter-default;
      position: relative;
  
      td, th {
        // border: 1px solid $color-black;
        padding: 8px;
        &.col_rekomendasi, tr.total &.col_rekomendasi {
          background-color:#909090;
        }
      }
  
      tr.total td.col_rekomendasi {
        background-color:#909090;
      }
  
      td {
        font-size: $font-size-default;
      }
  
      th {
        font-size: $font-size-default;
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
      }
  
      thead {
        tr {
          th {
            position: sticky;
            top: -29px;
            z-index: 2;
          }
          &:nth-child(2) th {
            top: 21px;
          }
        }
      }
    }
  
    &__add-more {
      display: flex;
      align-items: center;
  
      div {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }
  
    &__row {
      min-width: 20rem;
  
      &--30 {
        min-width: 30rem;
      }
  
      &.nominal {
        .input__container {
          justify-content: flex-end;
        }
      }
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 1rem;
        }
      }
    }
  
    &__custom-checkbox {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 0;
  
        &:not(:nth-child(1)) {
          margin-left:18rem;
        }
      }
    }
  
    &__table-footer {
      td {
        &:first-child {
          text-align: right;
        }
      }
    }
  
    &__action-button {
      display: flex;
      flex-direction: row;
  
      button:nth-child(2) {
        margin-left: $gutter-extra-small;
      }
    }
  
    &__total-transfer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    &__summary{
        td {
          text-align: right;
          font-weight: bold;
        }
    }
  }