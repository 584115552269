.budget {

  &__content {
    margin-top: $gutter-default;
  }
  &__search {
    margin-left:80%;
    //  margin-right:$gutter-large;
   
    position: relative;
    width: 500px;
  }
}
