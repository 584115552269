.cash-journal-closing {
  font-size: 1.5em;
  table {
    border-spacing: 0;
  }
  td {
    padding: 5px;
  }
  th {
    text-align: left;
  }
  &-header{

  }
  .list-number {
    width: 20px;
  }
  .row-total-label {
    width: 25px;
  }
  .row-total-field {
    width: 220px;
    text-align: right;
    padding-right: 20px;
    input {
      text-align: right;
      padding: 0;
      border: 0;
      border-bottom: 2px dotted #000;
      border-radius: 0;
    }
  }
  .space {
    width: 200px;
  }
  .counter {
    width: 50px;
  }
  .node {
    width: 100%;
  }
  tr.sub-total{
    .title, .row-total-label, .row-total-field, .space {
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: bold;
      text-align: center;
      border-top: 2px solid #000;
    }
    .row-total-field {
      text-align: right;
    }
  }
  tr.total {
    .title {
        text-align: center;
        font-weight: bold;
    }
    td {
      border-bottom: 2px solid #000;
    }
    &:last-child {
      td {
        border-bottom: 2px double #000;
      }
    }
  }
  table.content {
    width: 100%;
    margin-top: 20px;
    th {
      padding: 5px;
    }
  }
}
