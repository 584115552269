.manage-staff2 {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }
  
    &__custom-form-row-space {
      margin-left: 1rem;
      width: 100%;
    }
  
    &__custom-form-row-city {
      display: flex;
      flex-direction: row;
      margin-top: -50rem;
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }
  
  
    &__custom-form-row-input {
      display: flex;
      flex-direction: row;
      
      &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
          width: 50%;
          &:not(:nth-child(1)) {
            margin-left: 1rem;
          }
        }
  }
  
  &__custom-form-margin-left {
      display: flex;
      flex-direction: row;
      margin-left: 1rem;
      width: 100%;
     
  }
    
    &__custom-form-column {
      display: flex;
      flex-direction: column;
  
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }
    &__custom-form-column-city {
      display: flex;
      flex-direction: column;
      
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: -7rem;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
  
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }
  }