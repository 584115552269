.list-form2 {
    &__wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  
    &__content {
      display: flex;
      width: calc(50% - #{$gutter-small / 2});
      flex-direction: row;
      margin-right: $gutter-small;
  
      &--full {
        display: flex;
        width: 100%;
        flex-direction: row;
      }
  
      &:nth-child(even) {
        margin-right: 0;
      }
  
      .input__disabled{
        input {
          color: #FFFFFF;
          background: #9e9e9e;
        }
      }
    }
  
    &__date-of-birth {
      > div:nth-child(2) {
        margin-left: 1rem;
      }
    }
  
    &__footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
  
      div:nth-child(1) {
        margin-right: $gutter-small;
      }
    }
  
    &__title {
      min-height: 4rem;
      background-clip: padding-box;
      outline: none;
      width: 100%;
      font-weight: bold;
      font-size: $font-size-medium;
    }
  }
  