.manage-employee-contract {

  &__title {
    font-size: $font-size-large;
  }

  &__button {
    display: flex;
    flex-direction: row;

    button {
      &:nth-child(2) {
        margin-left: $gutter-small;
      }
    }
  }
}
