.upload-student {

  &__content {
    margin-top: $gutter-default;
  }
  &__content2 {
    margin-top: $gutter-default;
    margin-bottom: $gutter-default;
  }
}
