.balance-report {

  &__form {
    margin-bottom: $gutter-default;
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__table_wrapper {
    width: 100%;
    border-collapse: collapse;
    tr td{
      padding: 0;
      vertical-align: top;
      &:nth-child(1) {
        border-right: 2px solid #000;
      }
    }

    .balance-report__table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: $gutter-default;

      thead {
        border-bottom: 2px solid #000000;
        .code-group {
          border-bottom: 1px solid #000000;
          text-align: center;
        }
        th {
          text-align: center;
        }
      }
      td, th {
        font-size:1.3rem;
        border: 1px solid $color-black;
        border-bottom: none;
        padding: 8px;
        background: none;
        color: #000000;
        border: none;
        &.nominal {
          text-align: right;
          width: 200px;
        }
      }

      tr {
        &.balance_report_row {
          &_class {
            font-weight: bold;
            font-size: 1.8rem;
            td {
              border: none;
            }
          }

          &_category {
            font-weight: bold;
            font-size: 1.6rem;
            td {
              border: none;
            }
          }
          &_group {
            td {
              border: none;
            }
          }
        }
        &.summary {
          td {
            border-top: 2px solid #000000;
            border-bottom: 2px solid #000000;
            border-right: none;
            background: #cccccc;
            margin-bottom: 10px;
            padding: 8px;
            font-size: 1.6rem;
            font-weight: bold;
            text-align: center;
          }
        }
        td.total {
          border-top: 2px solid #000000;
          border-bottom: 2px solid #000000;
          background: #cccccc;
          margin-bottom: 10px;
        }
      }

      tr td, tr th {
        padding: 8px;
        &.code {
          width: 90px;
        }
      }

      tr:nth-child(even){
        //background-color: #f2f2f2;
      }

      tr:nth-child(odd){
        //background-color: $color-white
      }

      tr:hover {
        //background-color: #ddd;
      }

      th {
        font-size: $font-size-default;
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
      }

      &__actions {
        display: flex;

        button:nth-child(1) {
          margin-right: $gutter-extra-small;
        }
      }
    }
  }



  &__row-black {
    background-color: #2e2f32;
    color: $color-white;
    text-align: center;
  }

  &__row-title {
    font-weight: bold;
  }
}
