.adjustment-journal {

  &__add-button {
    margin-bottom: $gutter-default;
  }

  &__date {
    width: 30%;
  }

  &__content {
    margin-top: $gutter-default;
  }
}

.view-adjustment-journal-table-container {
  margin-top: 20px;
  & > tr {
    vertical-align: top;
    & > td {
      vertical-align: top;
    }
  }
  .selected {
    background: #CCCCCC,
  }
}
