@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?yw8t07');
  src:  url('../fonts/icomoon.eot?yw8t07#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?yw8t07') format('truetype'),
  url('../fonts/icomoon.woff?yw8t07') format('woff'),
  url('../fonts/icomoon.svg?yw8t07#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plus:before {
  content: "\f067";
}
.icon-question:before {
  content: "\f128";
}
.icon-minus:before {
  content: "\f068";
}
.icon-music:before {
  content: "\f001";
}
.icon-search:before {
  content: "\f002";
}
.icon-heart:before {
  content: "\f004";
}
.icon-star:before {
  content: "\f005";
}
.icon-star-o:before {
  content: "\f006";
}
.icon-user:before {
  content: "\f007";
}
.icon-film:before {
  content: "\f008";
}
.icon-th:before {
  content: "\f00a";
}
.icon-th-list:before {
  content: "\f00b";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-search-plus:before {
  content: "\f00e";
}
.icon-search-minus:before {
  content: "\f010";
}
.icon-power-off:before {
  content: "\f011";
}
.icon-cog:before {
  content: "\f013";
}
.icon-gear:before {
  content: "\f013";
}
.icon-trash-o:before {
  content: "\f014";
}
.icon-home:before {
  content: "\f015";
}
.icon-file-o:before {
  content: "\f016";
}
.icon-clock-o:before {
  content: "\f017";
}
.icon-download:before {
  content: "\f019";
}
.icon-play-circle-o:before {
  content: "\f01d";
}
.icon-repeat:before {
  content: "\f01e";
}
.icon-rotate-right:before {
  content: "\f01e";
}
.icon-refresh:before {
  content: "\f021";
}
.icon-lock:before {
  content: "\f023";
}
.icon-bookmark:before {
  content: "\f02e";
}
.icon-camera:before {
  content: "\f030";
}
.icon-image:before {
  content: "\f03e";
}
.icon-photo:before {
  content: "\f03e";
}
.icon-picture-o:before {
  content: "\f03e";
}
.icon-map-marker:before {
  content: "\f041";
}
.icon-check-square-o:before {
  content: "\f046";
}
.icon-calendar:before {
  content: "\f073";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-bar-chart-o:before {
  content: "\f080";
}
.icon-comments:before {
  content: "\f086";
}
.icon-bell-o:before {
  content: "\f0a2";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-bell:before {
  content: "\f0f3";
}
.icon-angle-right:before {
  content: "\f105";
}
