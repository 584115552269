.komentar {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 0rem;
        }
      }
    }

    &__custom-form-column {
      display: flex;
      flex-direction: column;
  
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 300%;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }

    &__box {
        display: flex;
        // flex-direction: row;
        justify-content: space-between;
        
        &--square{
          font-size: $font-size-default;
          width: 200px;
          border: 1px solid black;
          padding: 20px;
          margin: 5px;
          margin-left: 100rem;

        }
             
        &--square2{
            font-size: $font-size-default;
            flex-direction: column;
            width: 200px;
            border: 1px solid black;
            padding: 80px;
            margin: 5px;
            margin-right: 10rem;
          }
        
      }


    &__header-title3 {
        display: flex;
        // flex-direction: row;
        justify-content: space-between;
        
        // align-items: flex-start;
        // margin-top: 10rem;
    
        &--left {
          
          font-size: $font-size-default;
          flex-direction: column;
          
        }
    
        &--right {
          font-size: $font-size-default;
          flex-direction: column;
          
        }
      }
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
  
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }
  }
  