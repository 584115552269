.isi-bidang {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 1rem;
        }
      }
    }

    &__custom-form-column {
      display: flex;
      flex-direction: column;
  
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 0rem;
        }
      }
    }
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    //   margin-left: 60rem;
    //   margin-top:-6rem;
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }
  }
  