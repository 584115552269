.breadcrumb {
  background:none;
  padding: 0;
  margin: 0;
  ol {
    list-style-type: none;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: $font-size-default;
  }

  &__item {
    display: flex;
    color: $color-white;

    &:not(:first-child)::before {
      padding: 0 1rem;
      color: $color-white;
      content: "/";
    }

    &:last-child {
      pointer-events: none;
    }

    &:not(:last-child) {
      color: $color-blue;
    }
  }
}
