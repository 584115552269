.admission-header {
  position: fixed;
  width: 100%;
  height: $banner-height;
  background-image: url('../img/background-merge.png');
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: $gutter-small;

  &__top {
    display: flex;
    height: $header-height;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    position: absolute;
    right: $gutter-small;
    left: $gutter-small;
    top: calc(#{$banner-height} - #{$header-height});
    background-color: rgba($color-white, .85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      &:nth-child(1) {
        font-size: $font-size-medium;
      }
      &:nth-child(2) {
        font-size: $font-size-large;
        font-weight: bold;
      }
    }
  }

  &__logo {
    width: 200px;
    height: auto;
  }

  &__top-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__search-form {
    flex: 1;
    margin-right: $gutter-small;
  }

  &__icon {
    width: 4rem;
    height: 4rem;
    margin-right: $gutter-small;
    border-radius: 50%;
    background-color: rgba($color-white, .3);
    color: $color-white;
    font-size: $font-size-default;
  }

  &__ypl-logo {
    margin-right: $gutter-small;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 $gutter-small;
  }

  &__bottom-content {
    flex: 1;
    padding: 0 $gutter-small;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba($color-white, .3);

    &__content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__greeting {
    color: $color-white;
    font-size: $font-size-medium;
  }

  &__logout {
    color: $color-white;
    padding: 1.2rem 1rem;
  }
}
