.header {
  position: fixed;
  width: 100%;
  height: $banner-height;
  background-image: url('../img/background-merge.png');
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: $gutter-small;

  &__top {
    display: flex;
    height: $header-height;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__top-left {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: $header-height;
    width: $sidebar-width;

    @media only screen and (max-width: $bp-medium) {
      width: 12rem;
    }
  }

  &__title {
    position: absolute;
    right: $gutter-small;
    left: $gutter-small;
    top: calc(#{$banner-height} - #{$header-height});
    background-color: rgba($color-white, .85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      &:nth-child(1) {
        font-size: $font-size-medium;
      }
      &:nth-child(2) {
        font-size: $font-size-large;
        font-weight: bold;
      }
    }
  }

  &__logo {
    width: 200px;
    height: auto;
  }

  &__top-right {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__search-form {
    flex: 1;
    margin-right: $gutter-small;
  }

  &__icon {
    width: 4rem;
    height: 4rem;
    margin-right: $gutter-small;
    border-radius: 50%;
    background-color: rgba($color-white, .3);
    color: $color-white;
    font-size: $font-size-default;
    position: relative;

    & > span {
      position: absolute;
      top: 13px;
      left: 12px;
    }
  }

  &__ypl-logo {
    margin-right: $gutter-small;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__bottom-right {
    flex: 1;
    padding: 0 $gutter-small;
    margin-right: $gutter-small;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba($color-white, .3);

    &__content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__greeting {
    color: $color-white;
    font-size: $font-size-medium;
  }

  &__logout {
    color: $color-white;
    padding: 1.2rem 1rem;
  }
}

@keyframes pulsar {
  0% {
      transform: scale(0);
      opacity: 1;
  }
  75% {
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: 0;
  }
}

@-webkit-keyframes pulsar {
  0% {
      -webkit-transform: scale(0);
      opacity: 1;
  }
  75% {
      opacity: 1;
  }
  100% {
      -webkit-transform: scale(1);
      opacity: 0;
  }
}

.pulsar {
  width: 48px;
  height: 48px;
  margin: -4px 0 0 -4px;
  position: relative;
}

.pulsar  .ring {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  border: 5px solid rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 100px 0 rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin-top: -50%;
  margin-left: -50%;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.pulsar  .ring:nth-child(1) {
  -webkit-animation: pulsar 2s 0s linear infinite;
  animation: pulsar 2s 0s linear infinite;
}

.pulsar  .ring:nth-child(2) {
  -webkit-animation: pulsar 2s .5s linear infinite;
  animation: pulsar 2s .5s linear infinite;
}

.pulsar  .ring:nth-child(3) {
  -webkit-animation: pulsar 2s 1s linear infinite;
  animation: pulsar 2s 1s linear infinite;
}

.pulsar  .ring:nth-child(4) {
  -webkit-animation: pulsar 2s 1.5s linear infinite;
  animation: pulsar 2s 1.5s linear infinite;
}
