.student-book {

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__content {
  }

  &__content-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
