.bank-journal {

  &__content {
    margin-top: $gutter-default;
  }

  &__action-button {
    margin-left: $gutter-extra-small;
  }
}
