.komposisi-siswa {

  &__custom-form {

  }



  &__custom-form-row {
    display: flex;
    flex-direction: row;
    
    &__field {
      display: flex;
      flex-direction: row;
      flex: 1;

      &:not(:nth-child(1)) {
        margin-left: 0rem;
      }
    }
  }

  &__custom-form-row-row {
    display: flex;
    flex-direction: row;
    width : 49%;
    &__field {
      display: flex;
      flex-direction: row;
      flex: 1;

      &:not(:nth-child(1)) {
        margin-left: 0rem;
      }
    }
  }
  &__custom-form-row-form {
      display: flex;
      flex-direction: row;
      
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-left: 5rem;
  
        
      }
    }
  
  &__custom-scrolllbar {

    overflow-y : auto;
}
  

  &__custom-form-column-text {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      font-weight: bold;
      margin-left: 5rem;
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 0rem;
        }
      }
    }
    &__custom-font {
      display: flex;
      font-size: 15px;
      
      flex-direction: column;
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
        font-weight: bold;
        font-size: 18px;
        margin-left:1rem;
        
        &:not(:nth-child(1)) {
          margin-left:20rem;
        }
      }
    }

    &__custom-checkbox {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-left: 1rem;
        
      }
  
    }

    
    &__custom-checkbox2 {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-left:5rem;
        
      }
  
    }

    &__custom-font2 {
      display: flex;
      font-size: 15px;
      flex-direction: column;
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left:-10rem;
        }
      }
    }

  &__custom-form-row-text {
      display: flex;
      flex-direction: row;
      margin-left: 2rem;
      margin-top: 2rem;

      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
        &:not(:nth-child(1)) {
          margin-left: -2rem;
        }
      }
    }

  &__custom-form-row2 {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 2rem;
        }
      }
    }

    &__custom-form-row3 {
      display: flex;
      flex-direction: row;
      width:50%;
      
    }

    &__custom-form-row4 {
      display: flex;
      flex-direction: row;
      width:50%;
      margin-left:1rem;
     
    }

  &__custom-form-row-check {
      display: flex;
      flex-direction: row;
      
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
        &:not(:nth-child(1)) {
          margin-left: -2rem;
        }
      }
    }

    &__custom-form-row-select {
      display: flex;
      flex-direction: row;
      width: 200%;
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
        
      }
    }

    &__custom-select {
      display: flex;
      flex-direction: row;
      
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-left:1rem;
      //   &:not(:nth-child(1)) {
      //     margin-left:5rem;
      //   }
      }
    }

  

  

  &__custom-form-column {
    display: flex;
    flex-direction: column;

    &__field {
      display: flex;
      flex-direction: column;
      flex: 1;
      &:not(:nth-child(1)) {
        margin-left: $gutter-small;
      }
    }
  }

  &__custom-form-column2 {
      display: flex;
      flex-direction: row;
      width: 52%;
      margin-left: 47rem;
     
    }

  &__custom-form-data-row{
      display: flex;
      flex-direction: row;
      width: 300px;
      margin-left: 3rem;
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
      }
  }

  &__content {
      position: relative;
      width: 500px;
    }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      &:nth-child(2) {
        margin-left: $gutter-small;
      }
    }
  }
}
