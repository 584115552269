.option {
  margin-bottom: $gutter-extra-small;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__container {
    width: 100%;
    display: flex;

    &--error {

    }
  }

  &__label {
    font-size: $font-size-small;

    &--error {
      color: $color-red;
    }
  }

  &__select {
    font-size: $font-size-large;
  }

  &__list-label {
    margin-right: $gutter-extra-small;
    margin-left: 2px;
  }

  &__preview {
    font-size: $font-size-default;
  }
}
