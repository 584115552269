.nilai-ktsp {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
       
  
        &:not(:nth-child(1)) {
          margin-left: 1rem;
        }
      }
    }

    &__custom-form-row2 {
        display: flex;
        flex-direction: row;
        
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
          width: 100%;
          
          &:not(:nth-child(1)) {
            margin-left: 1rem;
          }
        }
      }

      &__custom-form-row3 {
        display: flex;
        flex-direction: row;
        
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
          width: 50%;
          
          // &:not(:nth-child(1)) {
          //   margin-left: 1rem;
          // }
        }
      }

      &__custom-form-row-bobot {
        display: flex;
        flex-direction: row;
        margin-left: 50rem;
      }

      &__custom-font {
        display: flex;
        font-size: 100px;
        flex-direction: column;
        &__field {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-top: 3rem;
    
          &:not(:nth-child(1)) {
            margin-left:1rem;
          }
        }
      }
    
    &__custom-form-column {
      display: flex;
      flex-direction: column;
  
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 0rem;
        }
      }
    }

    &__custom-form-data-row{
        display: flex;
        flex-direction: row;
        width: 50%;
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
          
    
          &:not(:nth-child(1)) {
            margin-left: -1rem;
          }
        }
    }
   
  
    &__button-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
    
        button {
          &:nth-child(2) {
            margin-left: $gutter-small;
          }
          &:nth-child(3) {
            margin-left: $gutter-small;
          }
        }
      }
  }
  