.content-table {

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gutter-small;

    @media only screen and (max-width: $bp-medium) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__entries {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      margin-bottom: $gutter-small;
    }
  }
}
