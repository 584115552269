.book-content {
  display: flex;
  flex-direction: row;
  flex: 1;

  &__wrapper-left {
    display: flex;
    flex: 8;
    flex-direction: column;
  }

  &__wrapper-right {
    display: flex;
    flex: 2;
  }

  &__content {
    display: flex;
    width: calc(50% - #{$gutter-small / 2});
    flex-direction: row;
    margin-right: $gutter-small;

    &--full {
      display: flex;
      width: 100%;
      flex-direction: row;
    }

    &:nth-child(even) {
      margin-right: 0;
    }
  }

  &__content-wrapper {
    width: 100%;
  }

  &__student {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__detail-wrapper {
    width: 100%;
  }

  &__detail {
    display: flex;
    flex: 1;
    flex-direction: row;

    div {
      &:nth-child(1) {
        display: flex;
        flex: 5;
      }
      &:nth-child(2) {
        display: flex;
        flex: 5;
      }

      p {
        word-break: break-all;
        hyphens: auto;
        white-space: pre-wrap;
      }
    }
  }
}
