.sidebar {
  position: fixed;
  width: $sidebar-width;
  background-color: $color-grey-light-4;
  min-height: 100%;
  z-index: 1;

  &__wrapper {
    position: relative;
    display: flex;
    width: 31rem;
    flex-direction: column;

    &.collapsed {
      transition: 1s;
      left: 0rem;
      width: 7rem;
      .sidebar {
        transition: 1s;
        width: 7rem;

        &__menu-container {
          overflow: hidden;

          .collapsible-menu__list {
            display: none;
          }
          &__collapsed {
            display: none;
          }
        }
      }
    }

    &.expanded {
      transition: 1s;
      left: 0;
      width: 31rem;
      .sidebar {
        transition: 1s;
        width: $sidebar-width;

        &__menu-container {
          overflow: auto;
          &__collapsed {
            display: none;
          }
        }
      }
    }
  }


  &__menu-container {
    max-height: calc(100vh - #{$banner-height});
    padding-top: $gutter-medium;
    overflow: auto;
    position: relative;
  }

  &__user-dropdown {
    width: 100%;
    padding: $gutter-small 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--left {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__username {
    margin-left: $gutter-small;
  }

  &__menu {
    padding: 0 $gutter-small;

    &:last-child {
      margin-bottom: $gutter-default;
    }
  }

  &__item {

  }

  .navbar_toggle_button {
    position: absolute;
    top: 50%;
    margin-top:-9rem;
    background: white;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    right: -1.5rem;
    z-index: 2;
    box-shadow: 0 0 0.6rem;

    &:hover{
      box-shadow: 0 0 0.8rem;
      .navbar_toggle_content {
        color: #000000;
      }
    }
    & .navbar_toggle_content {
      font-size: 2rem;
      font-weight:bold;
      color: #757575;
      display: block;
      line-height: 4rem;
      width: 4rem;
      height: 4rem;
    }
  }
}
