.report {

    &__content {
      margin-top: $gutter-default;
    }
    &__content2 {
      margin-left:0rem;
      margin-top:2rem;
      //  margin-right:$gutter-large;
     
      position: relative;
      width: 500px;
    }
  }