@charset "UTF-8";
@font-face {
  font-family: 'fontello_icons';
  src: url("../font/fontello_icons.eot?5939799");
  src: url("../font/fontello_icons.eot?5939799#iefix") format("embedded-opentype"), url("../font/fontello_icons.woff2?5939799") format("woff2"), url("../font/fontello_icons.woff?5939799") format("woff"), url("../font/fontello_icons.ttf?5939799") format("truetype"), url("../font/fontello_icons.svg?5939799#fontello_icons") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello_icons';
    src: url('../font/fontello_icons.svg?5939799#fontello_icons') format('svg');
  }
}
*/
[class^="fontello-icon-"]:before, [class*=" fontello-icon-"]:before {
  font-family: "fontello_icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.fontello-icon-list-add:before {
  content: '\e800'; }

/* '' */
.fontello-icon-edit:before {
  content: '\e801'; }

/* '' */
.fontello-icon-search:before {
  content: '\e802'; }

/* '' */
.fontello-icon-back:before {
  content: '\e803'; }

/* '' */
.fontello-icon-trash-empty:before {
  content: '\e804'; }

/* '' */
.fontello-icon-font:before {
  content: '\e805'; }

/* '' */
.fontello-icon-print-1:before {
  content: '\e806'; }

/* '' */
.fontello-icon-print:before {
  content: '\e807'; }

/* '' */
.fontello-icon-forward-1:before {
  content: '\e808'; }

/* '' */
.fontello-icon-cancel:before {
  content: '\e809'; }

/* '' */
.fontello-icon-cancel-circled-1:before {
  content: '\e80a'; }

/* '' */
.fontello-icon-bold:before {
  content: '\e80b'; }

/* '' */
.fontello-icon-check:before {
  content: '\e80c'; }

/* '' */
.fontello-icon-right-open:before {
  content: '\e80d'; }

/* '' */
.fontello-icon-left-open:before {
  content: '\e80e'; }

/* '' */
.fontello-icon-down-open:before {
  content: '\e80f'; }

/* '' */
.fontello-icon-up-open:before {
  content: '\e810'; }

/* '' */
.fontello-icon-login:before {
  content: '\e811'; }

/* '' */
.fontello-icon-logout:before {
  content: '\e812'; }

/* '' */
.fontello-icon-cog:before {
  content: '\e813'; }

/* '' */
.fontello-icon-phone:before {
  content: '\e814'; }

/* '' */
.fontello-icon-folder:before {
  content: '\e815'; }

/* '' */
.fontello-icon-mail:before {
  content: '\e816'; }

/* '' */
.fontello-icon-list:before {
  content: '\e817'; }

/* '' */
.fontello-icon-floppy:before {
  content: '\e818'; }

/* '' */
.fontello-icon-user:before {
  content: '\e819'; }

/* '' */
.fontello-icon-key:before {
  content: '\e81a'; }

/* '' */
.fontello-icon-plus:before {
  content: '\e81b'; }

/* '' */
.fontello-icon-minus:before {
  content: '\e81c'; }

/* '' */
.fontello-icon-message:before {
  content: '\e81d'; }

/* '' */
.fontello-icon-download:before {
  content: '\e81e'; }

/* '' */
.fontello-icon-attention-circled:before {
  content: '\e81f'; }

/* '' */
.fontello-icon-attention:before {
  content: '\e820'; }

/* '' */
.fontello-icon-home:before {
  content: '\e821'; }

/* '' */
.fontello-icon-ok:before {
  content: '\e822'; }

/* '' */
.fontello-icon-spin3:before {
  content: '\e832'; }

/* '' */
.fontello-icon-check-empty:before {
  content: '\f096'; }

/* '' */
.fontello-icon-email:before {
  content: '\f0e0'; }

/* '' */
.fontello-icon-bell:before {
  content: '\f0f3'; }

/* '' */
.fontello-icon-folder-empty:before {
  content: '\f114'; }

/* '' */
.fontello-icon-folder-open-empty:before {
  content: '\f115'; }

/* '' */
.fontello-icon-attention-alt:before {
  content: '\f12a'; }

/* '' */
.fontello-icon-doc-text:before {
  content: '\f15c'; }

/* '' */
.fontello-icon-sort-name-up:before {
  content: '\f15d'; }

/* '' */
.fontello-icon-sort-name-down:before {
  content: '\f15e'; }

/* '' */
.fontello-icon-sort-alt-up:before {
  content: '\f160'; }

/* '' */
.fontello-icon-sort-alt-down:before {
  content: '\f161'; }

/* '' */
.fontello-icon-sort-number-up:before {
  content: '\f162'; }

/* '' */
.fontello-icon-sort-number-down:before {
  content: '\f163'; }

/* '' */
.fontello-icon-box:before {
  content: '\f187'; }

/* '' */
.fontello-icon-envelope-open:before {
  content: '\f2b6'; }

/* '' */
.fontello-icon-envelope-open-o:before {
  content: '\f2b7'; }

/* '' */
.fontello-icon-comments:before {
  content: '\f4ac'; }

/* '' */
