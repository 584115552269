.manage-adjustment-journal {
  th.selection, td.selection {
    text-align: center;
    width: 30px;
  }

  th.coa, td.coa {
    width: 250px;
  }
}
