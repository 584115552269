.general-journal {

  &__add-button {
    margin-bottom: $gutter-default;
  }

  &__date {
    width: 30%;
  }

  &__content {
    margin-top: $gutter-default;
  }
}
