.estimasi {

    &__form-table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: $gutter-default;
  
      td, th {
        border: 1px solid $color-black;
        padding: 8px;
      }
  
      td {
        font-size: $font-size-default;
      }
  
      th {
        font-size: $font-size-default;
        color: black;
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        background-color: white;
      }
    }
  
    // &__add-more {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   width: 50%;
    // }
  
    &__add-more {
        display: flex;
        align-items: center;
        width:10%;
    
        div {
          &:nth-child(2) {
            margin-left: $gutter-small;
          }
        }
      }
    &__table-footer {
      td {
        &:first-child {
          text-align: right;
        }
      }
    }
  }
  