.kelas-list {

    &__header {
      display: flex;
      flex-direction: row;
    }
  
    &__button {
      width: 100%;
  
      &:nth-child(2) {
        margin-left: $gutter-small;
      }
    }
  
    &__content {
      margin-top: $gutter-default;
    }
  }
  