.big-book-report {

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__content {
    border: 1px solid rgba($color-black, .5);
    margin-bottom: $gutter-default;
  }

  &__header {
    padding: $gutter-small;
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      &:nth-child(1) {
        flex: 2;
        font-weight: bold;
      }

      &:nth-child(2) {
        flex: 8;
      }
    }
  }

  &__table {
    border-collapse: collapse;
    width: 100%;

    td, th {
      border: 1px solid $color-black;
      padding: 8px;

      &.nominal {
        text-align: right;
        width: 15%;
        min-width: 150px;
      }
    }

    tr td, tr th {
      &:nth-child(1) {
        width: 1%;
      }
    }

    tr:nth-child(even){
      //background-color: #f2f2f2;
    }

    tr:nth-child(odd){
      //background-color: $color-white
    }

    tr:hover {
      //background-color: #ddd;
    }

    td {
      font-size: $font-size-default;
    }

    th {
      font-size: $font-size-default;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
    }

    &__actions {
      display: flex;

      button:nth-child(1) {
        margin-right: $gutter-extra-small;
      }
    }

    tr {
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        th {
          text-align: center;
        }
      }
    }
  }
}
