.manage-request {

  &__form-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: $gutter-default;

    td, th {
      border: 1px solid $color-black;
      padding: 8px;
    }

    tr td {
      &:nth-child(1) {
        width: 1%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 30%;
      }
    }

    td {
      font-size: $font-size-default;
    }

    th {
      font-size: $font-size-default;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
    }

    &__actions {
      display: flex;

      button:nth-child(1) {
        margin-right: $gutter-extra-small;
      }
    }
  }

  &__add-more {
    div {
      width: 10%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__table-footer {
    td {
      &:first-child {
        text-align: right;
      }
    }
  }
}
