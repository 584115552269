.payment_table{
  &__header {
    font-size: 1.4rem;
    &:first {
      margin: 0 0 20px 0;
    }
  }
  &__report {
    border-collapse: collapse;
    font-size: 1.4rem;
    width: 40rem;
    margin: 0 0 20px 0;
    td, th {
      padding: 0 5px;
      border: 1px solid #000000;
    }
    .index {
      width: 8%;
    }
    .periode {
      width: 28%;
    }
    .date {
      width: 30%;
      text-align: center;
    }
    .nominal {
      text-align: right;
    }
    .currency {
      float: left;
    }
  }
  &__liabilities {
    border-collapse: collapse;
    width: 40rem;
    font-size: 1.4rem;
    margin: 20px 0 20px 0;
    tr td:nth-child(1){
      width: 60%;
    }
    .total {
      border-top: 1px double #000000;
      &:nth-child(2) {
        text-align: right;
      }
    }
    .nominal {
      text-align: right;
    }
    .currency {
      float: left;
    }
  }
}

.payment_report__recap {
  border-collapse: collapse;
  font-size: 1.4rem;
  width: 40rem;
  margin: 0 0 20px 0;
  width: 100%;
  td, th {
    padding: 0 5px;
    border: 1px solid #000000;
  }
  tr td:nth-child(2){
    width: 10%;
  }
  tr th.months {
    width: 5%;
  }

  tr td.nominal{
    text-align: right;
    width: 5%;
  }

  .total {
    td {
      background: #DEDEDE;
      font-weight: bold;
      color: #000000;
    }
    td:nth-child(1) {
      text-align: center;
    }
  }
}
