.absensi-harian {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 10%;
  
        &:not(:nth-child(1)) {
          margin-left: 1rem;
        }
      }
    }
    &__custom-form-row-kls {
        display: flex;
        flex-direction: row;
        width: 20%;
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
         
    
          &:not(:nth-child(1)) {
            margin-left: 1rem;
          }
        }
      }
   
    &__custom-form-row-datadiri {
      margin-left: 1rem;
      width: 100%;
    }

    &__custom-form-column {
      display: flex;
      flex-direction: column;
      width: 150%;
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
  
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }
  }
  