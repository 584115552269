.admission {
  padding: $gutter-small;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__logo {
    padding: $gutter-small;
    width: 25rem;
    height: 25rem;
  }

  &__info {
    padding: $gutter-small;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__info-list {
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
      &:nth-child(1) {
        flex: 3;
        font-weight: bold;
        font-size: $font-size-medium;
      }

      &:nth-child(2) {
        flex: 7;
        font-size: $font-size-medium;
      }
    }
  }

  &__submit {
    margin-top: $gutter-default;
  }
}
