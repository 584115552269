@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: none;
  }

  50% {
    transform: scale(1.05);
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, .25);
  }

  100% {
    transform: scale(1);
    box-shadow: none;
  }
}

@-webkit-keyframes animatetop {
  from {
    top:-300px;
    opacity:0;
  }
  to {
    top:0;
    opacity:1;
  }
}

@keyframes animatetop {
  from {
    top:-300px;
    opacity:0;
  }
  to {
    top:0;
    opacity:1;
  }
}
